import { memo, useEffect, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from '../Stage.module.scss';
import { generateKey } from './../../../utils/helpers';

const YOUTUBE_API_SRC = 'https://www.youtube.com/player_api';

let asyncLoadYoutubeAPI;

const YoutubePlayer = forwardRef(({ videoId, aspectRatio, className = '', events, onReady, ...playerVars }, ref) => {
  const playerRef = useRef();
  useEffect(() => {
    if (!playerRef.current.id)
      playerRef.current.id = `yt_player_${generateKey()}`;

    let player;

    const initPlayer = YT => {
      player = new YT.Player(playerRef.current.id, {
        videoId,
        playerVars: {
          origin: window.location.origin,
          rel: 0,
          showinfo: 0,
          ...playerVars,
        },
        events: {
          onReady: onPlayerReady,
          ...events,
        },
      });
    };

    if (window.YT && window.YTReady) {
      initPlayer(window.YT);
    } else {
      if (!asyncLoadYoutubeAPI) {
        asyncLoadYoutubeAPI = new Promise(resolve => {
          window.onYouTubeIframeAPIReady = () => {
            resolve(window.YT);
            const event = new Event('ytreadybycontentblock');
            window.dispatchEvent(event);
            setTimeout(() => {
              window.YTReady = true;
            }, 0);
          };
          const tag = document.createElement('script');
          tag.src = YOUTUBE_API_SRC;
          document.body.appendChild(tag);
        });
      }

      asyncLoadYoutubeAPI.then(YT => {
        initPlayer(YT);
      });
    }

    window.addEventListener('ytreadybyprimitive', function () {
      initPlayer(window.YT);
    }, false);

    function onPlayerReady() {
      onReady && onReady(player);
    }
    return () => player?.destroy();
  }, [videoId, events, playerVars]);

  return (
    <div className={`${styles.frame} ${className}`} style={{ paddingBottom: aspectRatio }} ref={ref}>
      <div ref={playerRef} />
    </div>
  );
});

YoutubePlayer.propTypes = {
  model: PropTypes.object,
};

export default memo(YoutubePlayer);