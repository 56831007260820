var PROVIDER_REGEX = /(?:(?:https?:)?\/\/)?(?:[^.]+\.)?(\w+)\./i;
var YOUTUBE_VIDEOID_WITH_REST_REGEX = /(?:(?:v|vi|be|videos|embed)\/(?!videoseries)|(?:v|ci)=)([\w-]*)/i;
var VIMEO_VIDEOID_WITH_REST_REGEX = /(vimeo(?:cdn|pro)?)\.com\/(?:(?:channels\/[\w]+|(?:(?:album\/\d+|groups\/[\w]+|staff\/frame)\/)?videos?)\/)?(\w+)(?:_(\d+)(?:x(\d+))?)?(\.\w+)?/i;
var YOUTUBE_ID_LENGTH = 11;
var VIMEO_ID_MIN_LENGTH = 1;
export var VideoProviderType = {
  Youtube: 'youtube',
  Vimeo: 'vimeo'
};
export var getVideoData = function getVideoData(url) {
  if (!url) return null;
  var provider = getProvider(url);

  switch (provider) {
    case VideoProviderType.Youtube:
      var ytVideoId = getYoutubeVideoIdWithRest(url);
      return ytVideoId && ytVideoId.length === YOUTUBE_ID_LENGTH ? {
        provider: provider,
        videoId: ytVideoId
      } : null;

    case VideoProviderType.Vimeo:
      var vimeoVideoId = getVimeoVideoIdWithRest(url);
      return vimeoVideoId && !isNaN(vimeoVideoId) && (vimeoVideoId === null || vimeoVideoId === void 0 ? void 0 : vimeoVideoId.length) >= VIMEO_ID_MIN_LENGTH ? {
        provider: provider,
        videoId: vimeoVideoId
      } : null;

    default:
      return null;
  }
};
export function generateKey() {
  return Math.random().toString(36).substr(2, 9);
}
export function isSupportedVideoUrl(url) {
  var _getYoutubeVideoIdWit;

  var provider = getProvider(url);
  if (provider === VideoProviderType.Youtube && ((_getYoutubeVideoIdWit = getYoutubeVideoIdWithRest(url)) === null || _getYoutubeVideoIdWit === void 0 ? void 0 : _getYoutubeVideoIdWit.length) === YOUTUBE_ID_LENGTH) return true;else if (provider === VideoProviderType.Vimeo) {
    var vimeoVideoId = getVimeoVideoIdWithRest(url);
    return !isNaN(vimeoVideoId) && (vimeoVideoId === null || vimeoVideoId === void 0 ? void 0 : vimeoVideoId.length) >= VIMEO_ID_MIN_LENGTH;
  }
  return false;
}

function getProvider(url) {
  var match = url.match(PROVIDER_REGEX);
  if (!match) return null;

  switch (match[1].toLowerCase()) {
    case 'youtube':
    case 'youtu':
      return VideoProviderType.Youtube;

    case 'vimeo':
    case 'vimeopro':
    case 'vimeocdn':
      return VideoProviderType.Vimeo;

    default:
      return null;
  }
}

function getYoutubeVideoIdWithRest(url) {
  var match = url.match(YOUTUBE_VIDEOID_WITH_REST_REGEX);
  return match ? match[1] : null;
}

function getVimeoVideoIdWithRest(url) {
  var match = url.match(VIMEO_VIDEOID_WITH_REST_REGEX);
  return match ? match[2] : null;
}